<template>
  <div class="home">
    <section :style="{ backgroundImage: 'url(' + require('../../public/assets/i/bgImg.png') + ')' }"
             class=" bg-overlay bg-overlay-gradient px-0 mt-0 min-vh-80 vidToImg d-flex">
      <div class="container">
        <div class="text">
          <h1>{{ langcontent.stitle }}</h1>
          <p>{{ langcontent.sdecs }}Hayvanlarınızda verimliliği arttıracak <b>Maximum Performans</b> ürünler.</p>
          <a class="btn btn-soft-leaf2 rounded-pill mt-6 mb-0" href="#products">{{ langcontent.products }}</a>
        </div>
      </div>
    </section>



    <section class="wrapper bg-light">
      <div class="container py-14 py-md-16">
        <div class="row gx-lg-8 gx-xl-12 gy-12 align-items-center">
          <div class="col-lg-6 position-relative">
            <div
                class="btn btn-circle btn-primary disabled position-absolute counter-wrapper flex-column d-none d-md-flex"
                style="top: 50%; left: 50%; transform: translate(-50%, -50%); width: 170px; height: 170px;">
              <h3 class="text-white mb-1 mt-n2"><span
                  class="counter counter-lg">{{ new Date().getFullYear() - 2000 }}+</span></h3>
              <p>{{ langcontent.ctext }}</p>
            </div>
            <div class="row gx-md-5 gy-5 align-items-center">
              <div class="col-md-6 d-none d-md-block">
                <div class="row gx-md-5 gy-5">
                  <div class="col-md-10 offset-md-2">
                    <figure class="rounded"><img :src="data.img[0]" :alt="data.title" ></figure>
                  </div>
                  <div class="col-md-12">
                    <figure class="rounded"><img :alt="data.title" :src="data.img[1]"></figure>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <figure class="rounded"><img :alt="data.title" :src="data.img[2]"></figure>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <h3 class="display-3 mb-10">{{ data.title }}</h3>
            <p class="mb-7">{{ data.desc.slice(0, 300) }}...</p>
            <router-link class="btn btn-soft-leaf rounded-pill mt-6 mb-0" to="/kurumsal">{{
                langcontent.discover
              }}
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <section id="products" class="wrapper bg-light">
      <div class="container py-14 py-md-16">
        <div class="row mb-8 text-center">
          <div class="col-lg-9 col-xl-8 col-xxl-7 mx-auto">
            <h2 class="fs-16 text-uppercase text-primary mb-3">{{ langcontent.mark }}</h2>
            <h3 class="display-4">{{ langcontent.products }}</h3>
          </div>
        </div>
        <div class="containerT">
          <template v-for="item in cats.slice().reverse()">
            <router-link v-if="item.id == 4 || item.id == 1"
                         :style="{ backgroundImage: 'url(' + require('../../public/assets/i/rumi.png') + ')' }"
                         :to="'/urunler/'+item.seo" class="card card0">
              <div class="border">
                <h2>{{ item.name }}</h2>
              </div>
            </router-link>
            <router-link v-else :style="{ backgroundImage: 'url(' + require('../../public/assets/i/avi.png') + ')' }"
                         :to="'/urunler/'+item.seo" class="card card1">
              <div class="border">
                <h2>{{ item.name }}</h2>
              </div>
            </router-link>
          </template>
          <router-link :style="{ backgroundImage: 'url(' + require('../../public/assets/i/raw.png') + ')' }"
                       class="card card2" to="/hammaddeler">
            <div class="border">
              <h2>{{ langcontent.raw }}</h2>
            </div>
          </router-link>
        </div>
      </div>
    </section>
    <section class="wrapper bg-light">
      <div class="container py-14 py-md-10">
        <div class="row gx-3 gy-10 align-items-center">
          <div class="col-lg-5 offset-lg-1">
            <h3 class="display-4 mb-4">{{ lab[0].title }}</h3>
            <p class="mb-6">{{
                lab[0].desc
              }}</p>
            <a class="btn btn-soft-leaf rounded-pill mt-6 mb-0" rel="nofollow" target="_blank" href="https://www.cerenvet.com/">{{
                langcontent.discover
              }}
            </a>
          </div>
          <div class="col-lg-5 offset-lg-1">
            <figure><img :alt="lab[0].title" :src="lab[0].img" class="w-auto"/></figure>
          </div>
        </div>
      </div>
      <!--/.row -->
    </section>

  </div>
</template>
<script>
import iso from "../axios";

export default {
  data() {
    return {
      data: [],
      cats: [],
      lab: [],
      langcontent: [],
    }
  },
  created() {
    iso.get('langfix')
        .then(response => {
          this.langcontent = response.data[0]
          document.title = 'Bimark | ' + this.langcontent.home;
        })
        .catch(error => {
          console.log(error);
        })
    iso.get('about')
        .then(response => {
          this.data = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
    iso.get('cats')
        .then(response => {
          this.cats = response.data
        })
        .catch(error => {
          console.log(error);
        })

    iso.get('lab')
        .then(response => {
          this.lab = response.data
        })
        .catch(error => {
          console.log(error);
        })
  },
  beforeRouteLeave(to, from, next) {
    next();
    window.scrollTo(0, 0);
  },
  beforeRouteEnter(to, from, next) {
    next();
    window.scrollTo(0, 0);
  }
}
</script>

<style>
.vidToImg {
  background: center center no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
}

.vidToImg .text h1 {
  color: #fff;
  font-weight: 900;
  font-size: 3rem;
}

.vidToImg .text p {
  color: #fff;
  font-size: 1rem;
}

.btn-soft-leaf2 {
  background-color: #fff;
  color: #000;
}

.btn-soft-leaf2:hover {
  color: #000;
}

.containerT {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  grid-gap: 20px;
}

.containerT .border {
  height: 300px;
  width: 400px;
  background: transparent;
  border-radius: 10px;
  transition: border 1s;
  position: relative;
}

.containerT .border:hover {
  border: 1px solid #fff;
}

.containerT .card {
  height: 300px;
  width: 400px;
  border-radius: 10px;
  transition: background 0.8s;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.containerT .card0 {
  background: center center no-repeat;
  background-size: 350px;
}

.containerT .card0:hover {
  background-size: 450px;
}

.containerT .card0:hover h2 {
  opacity: 1;
}

.containerT .card0:hover .fa {
  opacity: 1;
}

.containerT .card1 {
  background: center center no-repeat;
  background-size: 350px;
}

.containerT .card1:hover {
  background-size: 450px;
}

.containerT .card1:hover h2 {
  opacity: 1;
}

.containerT .card1:hover .fa {
  opacity: 1;
}

.containerT .card2 {
  background: center center no-repeat;
  background-size: 350px;
}

.containerT .card2:hover {
  background-size: 450px;
}

.containerT .card2:hover h2 {
  opacity: 1;
}

.containerT .card2:hover .fa {
  opacity: 1;
}

.containerT h2 {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #000;
  margin: 20px;
  opacity: 0;
  transition: opacity 1s;
}

.slider {
  width: 100vw;
  height: 100vh;
}

.slideItem {
  width: 100vw;
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}

/*.slideItem:after {*/
/*  content: '';*/
/*  position: absolute;*/
/*}*/

.slideItem video {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100vw;
  min-height: 100vh;
}

.slideItem img {
  display: none;
}

@media (max-width: 768px) {
  .slideItem video {
    display: none;
  }

  .slideItem img {
    display: block;
    height: 100vh;
    object-fit: cover;
  }
}

.sliderimg {
  -o-object-fit: cover;
  object-fit: cover;
  display: block;
  position: absolute;
  height: 100% !important;
  width: 100% !important;
  max-width: 100% !important;
  top: 0;
  left: 0;
  background: #000;
}

.carousel {
  padding: 0 !important;
  margin-top: 0 !important;
}

@media (max-width: 768px) {
  .carousel__prev {
    left: 30px !important;
  }

  .carousel__next {
    right: 30px !important;
  }
}

.video-wrapper {
  height: calc(100vh - 50px);
}
</style>