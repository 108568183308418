import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
const routes = [
  {
    path: '/',
    name: 'Anasayfa',
    component: Home,
    meta: {
      header: 'home',
    }
  },
  {
    path: '/kurumsal',
    name: 'Kurumsal',
    component: () => import('../views/About'),
    meta: {
      header: 'default'
    }
  },
  {
    path: '/avicenter/:avi',
    name: 'Avicenter',
    component: () => import('../views/Avicenter'),
    meta: {
      header: 'custom'
    }
  },
  {
    path: '/hammaddeler',
    name: 'Hammaddeler',
    component: () => import('../views/Materials'),
    meta: {
      header: 'custom'
    }
  },
  {
    path: '/urunler/:cat',
    name: 'Ürünler',
    component: () => import('../views/Cats'),
    meta: {
      header: 'custom'
    }
  },
  {
    path: '/rumicenter/:rumi',
    name: 'Rumicenter',
    component: () => import('../views/Rumicenter'),
    meta: {
      header: 'custom'
    }
  },
  {
    path: '/kariyer',
    name: 'Kariyer',
    component: () => import('../views/Career'),
    meta: {
      header: 'default'
    }
  },
  {
    path: '/yonet',
    name: 'Yönet',
    component: () => import('../views/Admin'),
    meta: {
      header: 'default'
    }
  },
  {
    path: '/iletisim',
    name: 'İletişim',
    component: () => import('../views/Contact'),
    meta: {
      header: 'default'
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/NotFound')
  }
  ,
  {
    path: '/:catchAll(.*)',
    redirect: '/404',
  }
]



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start()
  }
  next()
})

router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})


router.beforeEach((to, from, next)=>{
  document.title = 'Bimark | '+to.name
  next()
})
export default router