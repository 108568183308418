<template>
  <footer class="bg-dark text-inverse pt-14">
    <div class="container pb-7">
      <div class="row gx-lg-0 gy-6">
        <div class="col-lg-4">
          <div class="widget">
            <img class="mb-4" src="../../public/assets/i/logo-light.png" srcset="../../public/assets/i/logo-light@2x.png 2x" alt="" />
            <p class="lead mb-0">© {{new Date().getFullYear()}} {{ langcontent.copy }}</p>
          </div>
        </div>
        <div class="col-md-6 col-lg-3 offset-lg-2">
          <div class="widget">
            <div class="d-flex flex-row">
              <div>
                <div class="icon text-primary fs-28 me-4 mt-n1"> <i class="uil uil-phone-volume"></i> </div>
              </div>
              <div>
                <h5 class="mb-1">{{ langcontent.phone }}</h5>
                <p class="mb-0">
                  <a :href="'tel:'+contact.phone">{{ contact.phone }}</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-3">
          <div class="widget">
            <div class="d-flex flex-row">
              <div>
                <div class="icon text-primary fs-28 me-4 mt-n1"> <i class="uil uil-envelope"></i> </div>
              </div>
              <div class="align-self-start justify-content-start">
                <h5 class="mb-1">{{ langcontent.email }}</h5>
                <p class="mb-0">
                <a class="link-body" :href="'mailto:'+contact.mail"><span>{{ contact.mail }}</span></a><br>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="mt-10 mb-3" />
      <div class="d-md-flex align-items-center justify-content-between">
        <p class="mb-2 mb-lg-0">Powered By <a href="//gifadwork.com" target="_blank">Gif Adw.</a></p>
        <nav class="nav social social-muted mb-0 text-md-end d-none">
          <a href="#"><i class="uil uil-facebook-f"></i></a>
          <a href="#"><i class="uil uil-instagram"></i></a>
          <a href="#"><i class="uil uil-youtube"></i></a>
        </nav>
      </div>
    </div>
  </footer>
</template>
<script>
import iso from "../axios";
export default {
  name: "Footer",
  data(){
    return{
      langcontent: [],
      contact: [],
    }
  },
  created () {
    iso.get('langfix')
        .then(response => {
          this.langcontent = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })

    iso.get('contact')
        .then(response => {
          this.contact = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })

  }
}
</script>
<style scoped>
.mb-1{
  color: white;
}
</style>