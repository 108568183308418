import Axios from "axios";
if (!localStorage.lang) {localStorage.setItem('lang','tr')}
const instance = Axios.create({
    baseURL : "https://yonet.gifadwork.com/api/"+localStorage.getItem('lang')+"/bimark/",
    // httpsAgent: new https.Agent({
    //     ca: fs.readFileSync(`${path}CA.pem`),
    //     cert: fs.readFileSync(`${path}CERT.pem`),
    //     key: fs.readFileSync(`${path}KEY.pem`),
    //     auth: 'user:password',
    //     rejectUnauthorized: false
    // })
})

//instance.defaults.headers.common['Authorization'] = 'Bearer 1|FYPcAcKUQij6SGhjef9OkmjJDgaXn3OtZ01nCfwK'
//instance.defaults.headers.common['Accept'] = "application/json"
//instance.defaults.headers.common['Content-Type'] = "application/json"

export default instance;
/*
class ApiHelper {


    constructor() {}
    aaa(){
        alert('sa')
    }

    /*

    static url = 'api.ayyildizssoft.com.tr/etercuman/api/';
    static siteurl = 'api.ayyildizssoft.com.tr/etercuman';
    static token;
    static user;
    static photos;

    loginHeaders() {
        return {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            //'Authorization' : 'Bearer '+token
        };
    }

    logoutHeaders() {
        return {
            'Authorization' : 'Bearer '+ this.token
        };
    }

    setHeaders() {
        return {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            'Authorization' : 'Bearer '+ this.token
        };
    }

    async  _getToken() {
       this.token = sessionStorage.getItem("token");
    }

//     async  getUserfromSession()  {
//     SharedPreferences localStorage = await SharedPreferences.getInstance();
//
//     return user = jsonDecode(localStorage.getString('user'));
// }

// async getPhotosfromSession()  {
//     SharedPreferences localStorage = await SharedPreferences.getInstance();
//
//     return photos = jsonDecode(localStorage.getString('photos'));
// }

async authData(data, apiUrl,type)  {
    var fullUrl = url + apiUrl;

    if(type==="login"){

        const requestOptions = {
            method: "POST",
            headers: loginHeaders(),
            body: JSON.stringify({ data })
        };
        const response = await fetch(fullUrl, requestOptions);
        return await response.json();

       // return await http.post(fullUrl, body: jsonEncode(data), headers:_loginHeaders());
    }
    else if(type==="getUser"){
        await getToken();


        const requestOptions = {
            method: "GET",
            headers: setHeaders(),
            body: JSON.stringify({ data })
        };
        const response = await fetch(fullUrl, requestOptions);
        return await response.json();
    }
}

async  getData(apiUrl)  {
    var fullUrl = url + apiUrl;
    await getToken();

    const requestOptions = {
        method: "GET",
        headers: setHeaders(),
        // body: JSON.stringify({ data })
    };
    const response = await fetch(fullUrl, requestOptions);
    return await response.json();
}

async  getCurrentUserPosts(apiUrl) {
    var fullUrl = url + apiUrl;
    await getToken();

    const requestOptions = {
        method: "GET",
        headers: setHeaders(),
        // body: JSON.stringify({ data })
    };
    const response = await fetch(fullUrl, requestOptions);
    return await response.json();
}

async  logoutUserAPI(apiUrl) {
    var fullUrl = url + apiUrl;

    const requestOptions = {
        method: "DELETE",
        headers: logoutHeaders(),
        // body: JSON.stringify({ data })
    };
    const response = await fetch(fullUrl, requestOptions);
    return await response.json();

    // return await http.delete(fullUrl, headers: _logoutHeaders());
}

// async  getImagefromURL(imageURL) {
//     var fullUrl = siteurl + "/storage/" + imageURL;
//     return Image.network(fullUrl, fit: BoxFit.cover,);
// }

*/

//}