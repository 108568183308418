<template>
  <header class="wrapper">
    <nav class="navbar navbar-expand-lg classic transparent position-fixed navbar-dark">
      <div class="container flex-lg-row flex-nowrap align-items-center">
        <div class="navbar-brand w-100">
          <router-link to="/">
            <img class="logo-dark" src="../../public/assets/i/logo.png" srcset="../../public/assets/i/logo@2x.png 2x" alt="bimark logo" />
            <img class="logo-light" src="../../public/assets/i/logo-light.png" srcset="../../public/assets/i/logo-light@2x.png 2x" alt="bimark logo white" />
          </router-link>
        </div>
        <div class="navbar-collapse offcanvas offcanvas-nav offcanvas-start">
          <div class="offcanvas-header d-lg-none">
            <img class="logo-light" src="../../public/assets/i/logo-light.png" srcset="../../public/assets/i/logo-light@2x.png 2x" alt="bimark logo mobile" />
            <button  @click="menuClose" type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div class="offcanvas-body ms-lg-auto d-flex flex-column h-100">
            <ul class="navbar-nav">
              <li class="nav-item">
                <router-link class="nav-link" to="/">{{ langcontent.home }}</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/kurumsal">{{ langcontent.corporate }}</router-link>
              </li>
              <li class="nav-item dropdown dropdown-mega">
                <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">{{ langcontent.products }}</a>
                <ul class="dropdown-menu mega-menu">
                  <li class="mega-menu-content">
                    <div class="row">
                      <div class="col-lg-12">
                        <ul class="list-unstyled">
                          <li v-for="item in cats.slice().reverse()">
                            <router-link class="dropdown-item" :to="'/urunler/'+item.seo">{{ item.name }}</router-link>
                          </li>
                          <li><router-link class="dropdown-item" to="/hammaddeler">{{ langcontent.raw }}</router-link></li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="https://cerenvet.com" target="_blank" rel="nofollow">{{ langcontent.lab }}</a>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/kariyer">{{ langcontent.career }}</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/iletisim">{{ langcontent.contact }}</router-link>
              </li>
            </ul>
            <div class="offcanvas-footer d-lg-none">
              <div>
                <a class="link-body" :href="'mailto:'+contact.mail"><span>{{ contact.mail }}</span></a><br>
                <a :href="'tel:'+contact.phone">{{ contact.phone }}</a>
                <nav class="nav social social-white mt-4 d-none">
                  <a href="#"><i class="uil uil-facebook-f"></i></a>
                  <a href="#"><i class="uil uil-instagram"></i></a>
                  <a href="#"><i class="uil uil-youtube"></i></a>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div class="navbar-other w-100 d-flex ms-auto">
          <ul class="navbar-nav flex-row align-items-center ms-auto">

            <li class="nav-item dropdown language-select text-uppercase">
              <a class="nav-link dropdown-item dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{ getLang }}</a>
              <ul class="dropdown-menu">
                <template v-for="item in langs">

                <li class="nav-item" style="cursor: pointer"   v-if="!(getLang == item.kisaltma)">
                  <a class="dropdown-item" @click="langChange(item.kisaltma)" >{{ item.kisaltma }}</a>
                </li>
                </template>

              </ul>
            </li>

            <li class="nav-item d-lg-none">
              <button class="hamburger offcanvas-nav-btn"><span></span></button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import iso from "../axios";
export default {
  name: "Header",
  data(){
    return{
      langs: [],
      langcontent: [],
      cats: [],
      contact: [],
      getLang : 'tr'
    }
  },
  created () {
    iso.get('langfix')
        .then(response => {
          this.langcontent = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
    iso.get('diller')
        .then(response => {
          let data = response.data
          for (let key in data) {
            this.langs.push(data[key])
          }
        })
    iso.get('cats')
        .then(response => {
          this.cats = response.data
        })
        .catch(error => {
          console.log(error);
        })
    iso.get('contact')
        .then(response => {
          this.contact = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
    localStorage.lang = localStorage.getItem('lang')
    this.getLang = localStorage.getItem('lang')
    window.addEventListener('scroll', this.sticky);

  },
  methods: {
    menuClose:function () {
      document.querySelector('.btn-close-white').click()
    },
    langChange(e) {
      const firstPath = window.location.pathname.split('/')[1];
      // || firstPath == 'urun'
      if (firstPath == 'urunler' || firstPath == 'rumicenter' || firstPath == 'avicenter' || firstPath == 'hammaddeler') {
        localStorage.lang = e
        document.location.href = "/";
      } else {
        localStorage.lang = e
        location.reload(true);
      }
    },
    sticky:function (){
      const s = window.scrollY
      const n = document.querySelector('.navbar')
      if (window.innerWidth > 768){
        if (s>50){
          n.classList.remove('navbar-dark')
          n.classList.add('sticky')
        }else{
          n.classList.add('navbar-dark')
          n.classList.remove('sticky')
        }
      }else{
        n.style.backgroundColor = 'rgba(0,0,0,0.4)'
      }
    }
  }
}
</script>

<style scoped>
.router-link-active.nav-link{
  transition: all .3s ease;
  color: #24b24b!important;
}
/*background: #383838;*/
/*border-radius: 0 0 30px 30px;*/
</style>